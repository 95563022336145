* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }
  html {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }
  .hidden-scroll::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  /* Ẩn nút tăng giảm trên trình duyệt Chrome, Safari, Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Ẩn nút tăng giảm trên trình duyệt Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
